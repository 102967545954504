<template>

<app-wrapper class="content">

	<app-wrapper :content="true" :text="true" class="content-content" v-html="$state.page.content" v-bgimage="$state.page.image" />

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.content {
	padding: 64px 0px;
}

.is-tablet .content {
	padding: 40px 0px;
}

.is-mobile .content {
	padding: 30px 0px;
}

.content-content {
	padding: 0px 560px 0px 0px;
	background-repeat: no-repeat;
	background-position: 100% 0px;
	background-size: 458px 499px;
}

.is-tablet .content-content {
	background-position: 50% 80px;
	background-size: 240px 262px;
	padding: 0px;
}

.is-mobile .content-content {
	background-position: 50% 40px;
	background-size: 240px 262px;
	padding: 0px;
}

.is-tablet .content-content >>> h2 {
	text-align: center;
	margin-bottom: 310px;
}

.is-mobile .content-content >>> h2 {
	text-align: center;
	margin-bottom: 280px;
}

</style>
